
import { defineComponent } from 'vue';
import { modalsNames } from '@/utils/modals';
import TransferUsdtForm from './TransferUsdtForm.vue';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalTransferUsdt',
  components: {
    CommonModal,
    TransferUsdtForm,
  },

  setup() {
    return {
      modalName: modalsNames.transferUsdt,
    };
  },
});
