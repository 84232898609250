
import store from '@/store';
import { computed, defineComponent } from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalTransactionInfo',
  components: { CommonModal },
  setup() {
    const modalName = modalsNames.trnInfo;
    const data = computed(() => store.getters.transactionInfoModalStore.infoData || []);

    return {
      modalName,
      data,
    };
  },
});
