import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransferBtcForm = _resolveComponent("TransferBtcForm")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('Transfer BTC')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TransferBtcForm)
    ]),
    _: 1
  }, 8, ["name", "title"]))
}