
import { defineComponent } from 'vue';
import { modalsNames } from '@/utils/modals';
import TransferBtcForm from './TransferBtcForm.vue';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalTransferBtc',
  components: {
    CommonModal,
    TransferBtcForm,
  },

  setup() {
    return {
      modalName: modalsNames.transferBtc,
    };
  },
});
