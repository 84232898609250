
import store from '@/store';
import {
  computed,
  defineComponent,

} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';

import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';
import CommonModal from './CommonModal.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalTransferUsdtAccept',
  components: {
    CommonModal,
    CommonButtonSecondary,
    CommonButtonPrimary,
    CommonLoader,
  },
  setup() {
    const modalName = modalsNames.transferUsdtAccept;

    const transferState = computed(() => {
      const { state } = store.state.transferTokensStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);

    const data = computed(() => ({
      sum: hash.value.sum || '',
      email: hash.value.email || '',
    }));

    const closeHandler = modals.transferUsdt.closeModal;
    const onBack = () => modals.transferUsdt.openModal(data.value);

    const onSend = () => {
      store.dispatch.transferTokensStore.fetch({
        callback(state: State) {
          if (state === FULFILLED) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: data.value.sum as string,
        to_user: data.value.email as string,
        currency: 32,

      });
    };

    return {
      transferState,
      hideContent,
      modalName,
      isDone,
      hash,
      onBack,
      onSend,
      data,
      closeHandler,
    };
  },
});
