import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-174d7be0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalNewContract" }
const _hoisted_2 = { class: "modalNewContract__inputsBox" }
const _hoisted_3 = ["value", "id", "checked"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "modalNewContract__amountBox" }
const _hoisted_6 = {
  key: 0,
  class: "inputErrorInfo"
}
const _hoisted_7 = {
  key: 1,
  class: "inputErrorInfo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonLoader = _resolveComponent("CommonLoader")!
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('New contract'),
    isModalNewContract: true,
    onCloseModal: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonLoader, {
        isPlaceholder: "",
        active: _ctx.formData.state === 'PENDING'
      }, null, 8, ["active"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contractsVariables?.data.data, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `inputWrap-${i}`,
              class: "modalNewContract__inputWrap"
            }, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.pickedValue) = $event)),
                value: item?.period,
                id: `contractPeriod-${i}`,
                type: "radio",
                name: "contractPeriod",
                class: "modalNewContract__input",
                checked: item?.period === _ctx.formData.pickedValue
              }, null, 8, _hoisted_3), [
                [_vModelRadio, _ctx.formData.pickedValue]
              ]),
              _createElementVNode("label", {
                for: `contractPeriod-${i}`,
                class: _normalizeClass([
            'modalNewContract__label',
            {
              modalNewContract__label_first: i === 0,
              modalNewContract__label_last: i === _ctx.periodsList.length - 1,
            }
          ])
              }, _toDisplayString(`${item?.period} ${_ctx.$t('month')}`), 11, _hoisted_4)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_CommonInput, {
            modelValue: _ctx.formData.amount,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.amount) = $event)),
            maxlength: String(_ctx.contractsVariables?.data.data[2].max_amount).length,
            required: "",
            style: 'font-weight: bold;',
            onKeypress: _ctx.amountKeydown,
            onInput: _ctx.clearUnNumberValuePerPaste,
            class: "modalNewContract__amount"
          }, {
            suffix: _withCtx(() => [
              _createTextVNode("ATES")
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Amount')) + " ", 1),
              (Number(_ctx.formData.amount) < 50)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(`${_ctx.$t('Minimum deposit amount')} 50 ATES`), 1))
                : _createCommentVNode("", true),
              (_ctx.isErrorInput(_ctx.contractsVariables?.data.data).value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('The amount exceeds the scope of the contract')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "maxlength", "onKeypress", "onInput"])
        ]),
        _createVNode(_component_CommonButtonPrimary, {
          class: "modalNewContract__button",
          disabled: _ctx.isErrorInput(_ctx.contractsVariables?.data.data).value
        || _ctx.formData.amount === undefined || Number(_ctx.formData.amount < 50),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.buyContract(_ctx.contractsVariables?.data.data)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Create')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["name", "title", "onCloseModal"]))
}