/* eslint-disable import/no-cycle */
import { TransactionsStateInterface } from '@/store/modules/transactionsStore';

interface FilterItem {
  field: string;
  value: any;
  operator: 'more' | 'less' | 'is';
}

const transformFiltersForApiRequest = ({ filters, limit, offset }: TransactionsStateInterface) => {
  const newFilters = [] as FilterItem[];
  let searchLogic: 'OR' | 'AND' = 'OR';

  Object.keys(filters).forEach((key) => {
    const value: any = filters[key];

    if (value) {
      if (key === 'timestamp') {
        searchLogic = 'AND';
        if (filters.timestamp?.start) {
          newFilters.push({
            field: 'date',
            value: filters.timestamp?.start,
            operator: 'more',
          });
        }
        if (filters.timestamp?.end) {
          newFilters.push({
            field: 'date',
            value: filters.timestamp?.end,
            operator: 'less',
          });
        }
      } else if (key === 'marketplace') {
        searchLogic = 'AND';
        newFilters.push({
          field: 't.ref_table',
          value: 'marketplace',
          operator: 'is',
        });
        newFilters.push({
          field: 't.ref_table_id',
          value: filters[key],
          operator: 'is',
        });
      } else if (key === 't.type') {
        if (Array.isArray(value) && value.length && value[0]) {
          value.map((item: number | string) => {
            newFilters.push({
              value: item,
              field: 't.type',
              operator: 'is',
            });
            return item;
          });
        }
      } else {
        newFilters.push({
          value,
          field: key,
          operator: 'is',
        });
      }
    }
  });

  return JSON.stringify({
    search: newFilters,
    searchLogic,
    sort: [
      {
        field: 'date',
        direction: 'desc',
      },
    ],
    limit,
    offset,
  });
};

export default transformFiltersForApiRequest;
