import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TechWorksPlaceholder = _resolveComponent("TechWorksPlaceholder")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_ModalTransactionInfo = _resolveComponent("ModalTransactionInfo")!
  const _component_ModalTransferBtc = _resolveComponent("ModalTransferBtc")!
  const _component_ModalTransferBtcAccept = _resolveComponent("ModalTransferBtcAccept")!
  const _component_ModalTransferUsdt = _resolveComponent("ModalTransferUsdt")!
  const _component_ModalTransferUsdtAccept = _resolveComponent("ModalTransferUsdtAccept")!
  const _component_ModalWithdrawBtc = _resolveComponent("ModalWithdrawBtc")!
  const _component_ModalWithdrawBtcAccept = _resolveComponent("ModalWithdrawBtcAccept")!
  const _component_ModalWithdrawEuro = _resolveComponent("ModalWithdrawEuro")!
  const _component_ModalWithdrawEuroAccept = _resolveComponent("ModalWithdrawEuroAccept")!
  const _component_ModalWithdrawOton = _resolveComponent("ModalWithdrawOton")!
  const _component_ModalWithdrawOtonAcept = _resolveComponent("ModalWithdrawOtonAcept")!
  const _component_ModalContractsInfo = _resolveComponent("ModalContractsInfo")!
  const _component_ModalTwoAuth = _resolveComponent("ModalTwoAuth")!
  const _component_ModalWithdrawUsdt = _resolveComponent("ModalWithdrawUsdt")!
  const _component_ModalWithdrawUsdtAccept = _resolveComponent("ModalWithdrawUsdtAccept")!
  const _component_ModalPurchasesInfo = _resolveComponent("ModalPurchasesInfo")!
  const _component_ModalInfo = _resolveComponent("ModalInfo")!
  const _component_ModalAcceptDocs = _resolveComponent("ModalAcceptDocs")!
  const _component_ModalAllBranches = _resolveComponent("ModalAllBranches")!
  const _component_ModalPaymentFail = _resolveComponent("ModalPaymentFail")!
  const _component_ModalPaymentSuccess = _resolveComponent("ModalPaymentSuccess")!
  const _component_ModalNewContract = _resolveComponent("ModalNewContract")!

  return (_ctx.showTechWorks)
    ? (_openBlock(), _createBlock(_component_TechWorksPlaceholder, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_openBlock(), _createBlock(_component_router_view, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalConfirm, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTransactionInfo, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTransferBtc, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTransferBtcAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTransferUsdt, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTransferUsdtAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawBtc, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawBtcAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawEuro, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawEuroAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawOton, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawOtonAcept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalContractsInfo, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalTwoAuth, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawUsdt, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalWithdrawUsdtAccept, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalPurchasesInfo, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalInfo, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalAcceptDocs, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalAllBranches, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalPaymentFail, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalPaymentSuccess, { key: _ctx.locale })),
        (_openBlock(), _createBlock(_component_ModalNewContract, { key: _ctx.locale }))
      ], 64))
}