
import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';

import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalWithdrawBtcAccept',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonModal, CommonLoader,
  },
  setup() {
    const withdrawState = computed(() => {
      const { state } = store.state.withdrawTokensStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });

    const modalName = modalsNames.withdrawBtcAccept;

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);

    const data = computed(() => ({
      btcaddress: hash.value.btcaddress as string || '',
      iban: (hash.value.iban || '') as string,
      beneficiaryName: (hash.value.beneficiaryName || '') as string,

      sumWithFee: hash.value.sumWithFee as string || '',
      fee: (hash.value.fee || '') as string,
      sum: hash.value.sum as string || '',

      tab: (hash.value.tab || 'btc') as 'btc',
    }));

    const closeHandler = modals.withdrawBtcAccept.closeModal;
    const onBack = () => modals.withdrawBtc.openModal(data.value);
    const onSend = () => {
      const {
        tab, sumWithFee, btcaddress, beneficiaryName, iban,
      } = data.value;

      const destinations = {
        btc: btcaddress,
        paystree: `paystree|--|${iban}|--|${beneficiaryName}`,
      };

      const destination = destinations[tab] || '';

      store.dispatch.withdrawTokensStore.fetch({
        callback(state: State) {
          if (state === FULFILLED) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: sumWithFee,
        destination,
        currency: 1,
      });
    };

    return {
      withdrawState,
      forms: {
        btc: [
          {
            label: 'BTC address',
            name: 'btcaddress',
          },
        ],
        paystree: [
          {
            label: 'Beneficiary name',
            name: 'beneficiaryName',
          },
          {
            label: 'IBAN',
            name: 'iban',
          },
        ],
      },
      modalName,
      isDone,
      hideContent,
      data,
      closeHandler,
      onBack,
      onSend,
    };
  },
});
