
import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';
import curr from 'currency.js';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';

import changeCurr from '@/utils/changeCurr';
import { pathOr } from 'rambda';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalWithdrawOtonAccept',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonModal, CommonLoader,
  },
  setup() {
    const withdrawState = computed(() => {
      const { state } = store.state.withdrawTokensStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });
    const modalName = modalsNames.modalWithdrawOtonAccept;

    const otonqBalance = computed(() => pathOr('0', 'balance.otonq.balance', store.getters.userStore.data));

    const otonqInNum = computed(() => Number(otonqBalance.value.replace(/,/g, '')));

    const otonqInEur = computed(() => changeCurr('oton', 'usd', otonqInNum.value));

    const otonqInBtc = computed(() => changeCurr('oton', 'btc', otonqInNum.value));

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);

    const data = computed(() => ({
      bep20: (hash.value.bep20 || '') as string,
      fee: hash.value.fee as 'btc' | 'usd',

      beneficiaryName: hash.value.beneficiaryName || '',
      iban: hash.value.iban || '',

      sum: hash.value.sum as string || '',

      tab: (hash.value.tab || 'bep20') as 'bep20',
    }));
    const feesValues = computed(() => {
      const otonSum = curr((+data.value.sum || 0));
      const otonRate = Math.trunc(curr((+data.value.sum || 0)).multiply(0.99).value);
      let otonFee = otonSum.subtract(otonRate).value;

      if (otonFee < 20) {
        otonFee = 20;
      }

      return {
        oton: otonFee,
        eur: 1.5,
        btc: changeCurr('usd', 'btc', 1.5).format(),
      };
    });

    const sumWithFee = computed(() => {
      const withFee = curr(+data.value.sum || 0).subtract(feesValues.value.oton).value;
      return withFee > 0 ? withFee : 0;
    });

    const closeHandler = modals.modalWithdrawOtonAccept.closeModal;
    const onBack = () => modals.modalWithdrawOton.openModal(data.value);
    const onSend = () => {
      const {
        sum, bep20, fee, beneficiaryName, iban, tab,
      } = data.value;
      const destinations = {
        bep20: String(bep20),
        paystree: `paystree|--|${iban}|--|${beneficiaryName}`,
      };

      const destination = destinations[tab] || '';

      store.dispatch.withdrawTokensStore.fetch({
        callback(state: State) {
          if (state === FULFILLED) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: sum,
        currency: 41,
        destination,
        comission: fee,
      });
    };

    return {
      withdrawState,
      forms: {
        bep20: [
          {
            label: 'BEP20-address',
            name: 'bep20',
          },
        ],
        paystree: [
          {
            label: 'Beneficiary name',
            name: 'beneficiaryName',
          },
          {
            label: 'IBAN',
            name: 'iban',
          },
        ],
      },
      sumWithFee,
      modalName,
      isDone,
      hideContent,
      otonqBalance,
      otonqInNum,
      otonqInEur,
      otonqInBtc,
      data,
      feesValues,
      closeHandler,
      onBack,
      onSend,
    };
  },
});
