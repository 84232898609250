/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone, omit } from 'rambda';

import {
  OfficeTxItemInterface,
  // TransactionsItemInterface,
} from '@/interfaces/OfficeTxTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicAdapterFetch, State } from '@/store/storeUtils';
import { INIT, PENDING, FULFILLED } from '@/utils/statuses';

import debounce from 'debounce';
import authCookies from '@/utils/cookies/authCookies';
import transformFiltersForApiRequest from '@/utils/transformFilters';

export type TransactionResponse = AxiosResponse<{ data: OfficeTxItemInterface }>;

export interface TransactionsStateInterface {
  data: OfficeTxItemInterface[];
  fetchState: State;
  canFetchMore: boolean;
  limit: number;
  offset: number;

  filters: {
    timestamp?: {
      start: number;
      end: number;
    };
    marketplace?: string;
    't.currency_id'?: string;
    't.status'?: string | number;
    't.type'?: string | number | string[] | number[];
    // 'ttt.impact'?: '-' | '+' | '+|-';
    [index: string | number]: any;
  };
}

export const initialState: TransactionsStateInterface = {
  data: [],
  fetchState: INIT,

  canFetchMore: false,
  offset: 0,
  limit: 25,

  filters: {
    't.type': [''],
    't.status': '',
    't.currency': '',
    // 'ttt.impact': '+|-',
    marketplace: '',
  },
};

const transactionsStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchMoreState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length > 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (state.data.length <= 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    PREPARE_FOR_FIRST_FETCH(state) {
      Object.assign(state, omit(['filters'], initialState));
    },
    SET_STATE(state, { fetchState, data }: { fetchState: State; data: TransactionResponse }) {
      state.fetchState = fetchState;

      const response: number = pathOr(1, ['data', 'code'], data);

      if (response === 0) {
        const list = pathOr([], ['data', 'data', 'records'], data);

        state.canFetchMore = list.length >= state.limit;

        state.data = [...state.data, ...list];
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
    SET_FILTERS(state, { filters }: { filters: TransactionsStateInterface['filters'] }) {
      state.filters = filters;
    },
    PREPARE_FOR_FILTERS(state) {
      Object.assign(state, clone(omit(['filters'], initialState)));
    },
    SET_OFFSET(state, offset: number) {
      state.offset = offset;
    },
  },
  actions: {
    async createReq(ctx) {
      const { commit, state } = actionCtx(ctx);

      await basicAdapterFetch({
        method: api.office.sendPost,
        props: {
          url: '/transaction/listTransactions',
          data: {
            token: authCookies.get(),
            request: transformFiltersForApiRequest(state),
            // request: JSON.stringify({
            //   offset: state.offset,
            //   limit: state.limit,
            //   filter: state.filters,
            // }),
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.SET_OFFSET(state.data.length);
          }
        },
      });
    },

    async fetch(ctx, props: { fromZero?: boolean }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (props.fromZero) {
        commit.PREPARE_FOR_FIRST_FETCH();
      }

      await dispatch.createReq();
    },

    changeFilters: (() => {
      const debouncedFetch = debounce((func: any) => {
        func();
      }, 500);

      return (ctx: any, filters: TransactionsStateInterface['filters']) => {
        const {
          commit,
          //  state,
          dispatch,
        } = actionCtx(ctx);

        // if (state.fetchState === PENDING) {
        //   return;
        // }

        commit.SET_FILTERS({ filters });
        debouncedFetch(() => {
          commit.PREPARE_FOR_FILTERS();
          dispatch.createReq();
        });
      };
    })(),

    async createReqForUser(ctx, props:{userId: number}) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicAdapterFetch({
        method: api.office.sendPost,
        props: {
          url: '/transaction/listTransactions',
          data: {
            token: authCookies.get(),
            request: {
              cmd: 'get',
              limit: state.limit,
              offset: state.offset,
              sort: [{ field: 'date', direction: 'desc' }],
              search: [
                {
                  field: 't.status', type: 'int', operator: 'is', value: '2',
                }, {
                  field: 't.currency_id', type: 'int', value: 29, operator: 'is',
                }, {
                  field: 't.ref_user_id', type: 'int', value: props.userId, operator: 'is',
                },
              ],
              searchLogic: 'AND',
            },
          },
        },
        setState: commit.SET_STATE,
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.SET_OFFSET(state.data.length);
          }
        },
      });
    },

    async fetchForUser(ctx, props: { fromZero?: boolean, userId: number }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      if (props.fromZero) {
        commit.PREPARE_FOR_FIRST_FETCH();
      }

      await dispatch.createReqForUser({ userId: props.userId });
    },

    async clear(ctx) {
      const { commit } = actionCtx(ctx);
      commit.CLEAR_STORE();
    },
  },
});

export default transactionsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transactionsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, transactionsStore);
