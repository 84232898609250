import Cookies from 'js-cookie';

export interface SmartRef {
  place: 'left' | 'right' | 'default';
  id: number | string;
}
export interface RawRef {
  place: any;
  id: any;
}

const Ref = (() => {
  const normalizeRef = (ref: null | void | number | string | RawRef): SmartRef | null => {
    if (!ref) {
      return null;
    }

    if (typeof ref === 'string') {
      // test that string is a JSON string
      const isJson = /^{([\s\S]+)}?$/.test(ref);
      if (isJson) {
        let result;

        try {
          const parsedRef = normalizeRef(JSON.parse(ref)) as SmartRef;
          result = parsedRef;
        } catch (e) {
          console.error(e);
          return null;
        }

        return result;
      }

      return {
        place: 'default',
        id: ref,
      };
    }

    if (typeof ref === 'number') {
      return {
        place: 'default',
        id: ref,
      };
    }

    if (typeof ref?.id !== 'string' && typeof ref?.id !== 'number') {
      return null;
    }

    const result: SmartRef = {
      place: ref.place,
      id: ref.id,
    };
    const rePlace = /^(left|right|default)$/;

    if (!rePlace.test(ref.place)) {
      result.place = 'default';
    }

    return result;
  };

  const getFromRoute = (): SmartRef | null => {
    const searchParams = new URLSearchParams(window.location.search);
    const ref = searchParams.get('ref');
    const place = searchParams.get('place');

    const result = {
      place,
      id: ref,
    };

    return normalizeRef(result);
  };

  const getFromLS = (): SmartRef | null => {
    if (typeof window === 'undefined') {
      return null;
    }
    const ref = localStorage.getItem('ref');

    return normalizeRef(ref);
  };

  const getFromCookies = (): SmartRef | null => {
    if (typeof window === 'undefined') {
      return null;
    }
    const ref = Cookies.get('ref');

    return normalizeRef(ref);
  };

  const getFromRouteCookiesAndLS: () => SmartRef | null = () => {
    const fromRoute = getFromRoute();

    if (fromRoute) {
      return fromRoute;
    }

    const fromCookies = getFromCookies();

    if (fromCookies) {
      return fromCookies;
    }

    return getFromLS();
  };

  const saveInLS = (ref: SmartRef | string | number) => {
    const normalized = normalizeRef(ref);
    localStorage.setItem('ref', JSON.stringify(normalized));
  };

  const saveInCookie = (ref: SmartRef | string | number) => {
    const normalized = normalizeRef(ref);
    Cookies.set('ref', JSON.stringify(normalized));
  };

  const setRef = (ref: SmartRef | string | number) => {
    if (!ref) {
      return;
    }

    saveInCookie(ref);
    saveInLS(ref);
  };

  const save = () => {
    const ref = getFromRouteCookiesAndLS();

    if (!ref) {
      return;
    }

    setRef(ref);
  };
  const remove = () => {
    Cookies.remove('ref');
    Cookies.remove('ref', { domain: `.${window.location.hostname.split('.').slice(-2).join('.')}` });
    localStorage.removeItem('ref');
  };

  return {
    get: getFromRouteCookiesAndLS,
    remove,
    save,
    saveInLS,
    saveInCookie,
    setRef,
  };
})();

export default Ref;
