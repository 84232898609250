/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { AxiosResponse } from 'axios';

import { path } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  basicOfficeFetch, getFetchState, FetchStateInterface, getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

export interface UserStats {
  bonus: {
    own: number | string,
    structure: number | string,
  },
}

export type StatsResponse = AxiosResponse<{ data: UserStats }>;

const statsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StatsResponse>(),
  } as FetchStateInterface<StatsResponse>,

  getters: {
    data(...args): UserStats | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as UserStats;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/linear/stats',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in statsStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default statsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, statsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, statsStore);
