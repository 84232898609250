/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  basicOfficeFetch,
  getFetchState,
  FetchStateInterface,
  getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED, INIT,
} from '@/utils/statuses';

export interface TurnoverResponseInterface {
  cap: number | string;
  limited: number | string;
  needToNextRank: number | string;
  nextRank: number | string;
  structure: object;
  total: number | string;
}

export interface TurnoverStoreInterface extends FetchOfficeState<TurnoverResponseInterface> {
  data: TurnoverResponseInterface;
}

const TurnoverStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<TurnoverResponseInterface>(),
  } as FetchStateInterface<TurnoverResponseInterface>,

  getters: {
    data(...args): TurnoverResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path(['data', 'data'], state.response) as TurnoverResponseInterface;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/stats',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in twoAuthCodeStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default TurnoverStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, TurnoverStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, TurnoverStore);
