import { MappedChartItemInterface, ChartItemInterface, ChartPriceItemInterface } from '@/interfaces/OtonChartTypes';
import dayjs from 'dayjs';

export interface MapChartProps extends ChartItemInterface {
  [key: string]: any;
}

export const mapChartItems = (
  items: MapChartProps[],
): MappedChartItemInterface[] => items.map((item) => ({
  y: item.total,
  x: dayjs(item.date, 'YYY-MM-DD HH:mm:ss').format('DD.MM.YY'),
}));
export const mapPriceChartItems = (
  items: ChartPriceItemInterface[],
): MappedChartItemInterface[] => items.map((item) => ({
  y: +item.rate,
  x: dayjs(item.date, 'YYY-MM-DD HH:mm:ss').format('DD.MM.YY'),
}));
