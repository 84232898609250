
import store from '@/store';
import modals from '@/utils/modals';

import routeHash from '@/utils/routeHash';
import { computed, defineComponent } from 'vue';

import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonInput from './CommonInput.vue';

export default defineComponent({
  name: 'TransferUsdtForm',
  components: {
    CommonInput,
    CommonButtonPrimary,
  },
  setup() {
    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      sum: hash.value.sum || '',
      email: hash.value.email || '',
    }));

    const onChange = (name: string, value: string) => {
      routeHash.replace({ [name]: value });
    };
    const onSubmit = () => {
      modals.transferUsdtAccept.openModal(data.value);
    };

    return {
      data,
      onChange,
      onSubmit,
    };
  },
});
