import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047cafa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emailBox" }
const _hoisted_2 = { class: "sumBox" }
const _hoisted_3 = { class: "sumInputBox" }
const _hoisted_4 = { class: "feeInfo" }
const _hoisted_5 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonButtonPrimary = _resolveComponent("CommonButtonPrimary")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CommonInput, {
        modelValue: _ctx.data.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange('email', $event))),
        type: "email",
        required: true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("OTON user email")), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CommonInput, {
          modelValue: _ctx.data.sum,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange('sum', $event))),
          pattern: "^\\d*\\.?\\d*$",
          required: true
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("Sum")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("No fee")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CommonButtonPrimary, { type: "submit" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Transfer {sum} BTC", { sum: _ctx.data.sum })), 1)
        ]),
        _: 1
      })
    ])
  ], 32))
}