/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import {
  UserContactsFiltersInterface,
  UserContactsResponseInterface,
  UserContactItemInterface,
} from '@/interfaces/userContactsTypes';
import routeHash from '@/utils/routeHash';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
  State,
} from '@/store/storeUtils';
import {
  FULFILLED, INIT, PENDING, REJECTED,
} from '@/utils/statuses';

export interface UserStoreInterface extends FetchOfficeState<UserContactsResponseInterface> {
  filters: UserContactsFiltersInterface;
  editState: State;
}

const contactsUserStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<UserStoreInterface>(),
    editState: FULFILLED,
    filters: {
      cmd: 'get',
      limit: 100,
    },
  } as UserStoreInterface,

  getters: {
    data(...args): UserContactItemInterface[] | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data.records', state.response) as UserContactItemInterface[];

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),

    SET_INIT(state) {
      state.fetchState = INIT;
    },
    SET_EDIT_STATE(state: any, { fetchState }: { fetchState: State; data?: any }) {
      state.editState = fetchState;
    },
  },
  actions: {
    async fetchList(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/address/find',
          data: {
            request: state.filters,
          },
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            // dispatch.dropAuth();
          }
        },
      });
    },

    async updateContact(ctx, payload: {
      data: any;
      method: '/address/edit' | '/address/remove';
    }) {
      const { commit, state, dispatch } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: payload.method,
          data: payload.data,
        },
        setState: commit.SET_EDIT_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            dispatch.fetchList();
            routeHash.replace({}, true);
          }
        },
      });
    },

    async editContact(ctx, payload: UserContactItemInterface) {
      const { dispatch } = actionCtx(ctx);

      await dispatch.updateContact({
        data: { data: payload },
        method: '/address/edit',
      });
    },
    async removeContact(ctx, payload: number | string) {
      const { dispatch } = actionCtx(ctx);

      await dispatch.updateContact({
        data: { id: payload },
        method: '/address/remove',
      });
    },
  },
});

export default contactsUserStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, contactsUserStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, contactsUserStore);
