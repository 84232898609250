import { createI18n } from 'vue-i18n';
import getLang from '@/utils/cookies/getLang';
import { customPlureRule } from '@/hooks/tc';
import allowedLangs from './allowedLangs';
import strings from './strings';

const defaultDatesFormat = {
  loopshortfirst: {
    month: 'short',
    day: 'numeric',
  },
  loopShortFirst: {
    month: 'short',
    day: 'numeric',
  },
  dexDate: {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  loopShortSecond: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  commentDate: {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
  newsDate: {
    day: 'numeric',
    month: 'long',
  },
  rankDate: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  activityDate: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
  hoursAndMinutes: {
    hour: 'numeric',
    minute: 'numeric',
  },
  longNumeric: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

const dateTimeFormats: { [key: string]: typeof defaultDatesFormat } = {};

allowedLangs.forEach((l) => {
  dateTimeFormats[l] = defaultDatesFormat;
});

const i18n = (createI18n as any)({
  datetimeFormats: dateTimeFormats,
  legacy: false,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  locale: getLang() || 'en', // установка локализации по умолчанию
  fallbackLocale: 'en',
  messages: strings, // установка сообщений локализаций
  pluralRules: {
    ru: customPlureRule,
  },

  // mode: 'composition',
});

export const getLocale = () => {
  const locale = i18n.global.locale as any;

  if (typeof locale === 'object' && locale.value) {
    return locale.value;
  }

  return locale;
};

export default i18n;
