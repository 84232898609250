

import { defineComponent, reactive, computed } from 'vue';
import useSWRV, { mutate } from 'swrv';
import { api } from '@/utils/apiInstance';
import { basicOfficeFetch } from '@/store/storeUtils';
import '@/assets/icons/close-icon.svg?sprite';
import modals, { modalsNames } from '@/utils/modals';
import dividingIntoDigits from '@/utils/dividingIntoDigits';
import CommonModal from './CommonModal.vue';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalNewContract',
  components: {
    CommonModal, CommonInput, CommonButtonPrimary, CommonLoader,
  },
  setup() {
    const modalName = modalsNames.modalNewContract;

    const periodsList = [12, 36, 60];

    const formData = reactive({
      pickedValue: 12,
      amount: undefined,
      state: 'INIT',
    });

    const takeHashValue = (value: any) => {
      const hashValue = window.location.hash.split('&').find((item) => item.split('=')[0] === value);
      if (hashValue) {
        return hashValue.split('=')[1];
      }
      return '';
    };

    const findContract = (list: any) => computed(() => (list || []).find((item: any) => item.period === formData.pickedValue));
    const isContractAmountMoreInputAmount = (list: any) => computed(() => findContract(list).value?.max_amount < Number(String(formData.amount).replace(',', '.')));

    const isNodeAmountMoreInputAmount = (nodesList: any) => computed(() => (nodesList || []).find((item: any) => item.id === Number(takeHashValue('id')))?.max_amount_limit
      < Number(formData.amount || 0));

    const isErrorInput = (list: any) => computed(() => isContractAmountMoreInputAmount(list).value);
    const contractId = (list: any) => computed(() => (list || []).find((item: any) => item.period === formData.pickedValue)?.id);

    const prefetchNodesList = () => {
      mutate(
        '/contracts/getList',
        api.office.sendPost({ url: '/contracts/getList' }),
      );
    };

    const buyContract = (list: any) => {
      basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/contracts/order',
          data: {
            contract_id: +contractId(list).value,
            amount: Number(formData.amount),
          },
        },
        setState: ({ fetchState }) => {
          formData.state = fetchState;
        },
        callback: (fetchState, data) => {
          if (fetchState === 'FULFILLED') {
            formData.amount = undefined;
            if (typeof data.data.data[0] === 'string' && data.data.data[0].includes('https://')) {
              window.location.assign(data.data.data[0]);
            } else {
              modals.modalNewContract.closeModal();
              prefetchNodesList();
            }
          }
        },
      });
    };

    const contractsVariables = useSWRV('/contracts/index', null)?.data;

    const dividingNumber = (value: number) => dividingIntoDigits(value);

    const closeModal = () => {
      formData.amount = undefined;
      formData.pickedValue = 12;
    };

    const amountKeydown = (e: any) => {
      const keys = ['Backspace', 'ArrowLeft', 'ArrowRight'];
      if ((/\+|\.|-|,|=|_/.test(e.key) || !/[0-9]/g.test(e.key)) && !(keys.includes(e.key))) {
        e.preventDefault();
      }
    };
    const clearUnNumberValuePerPaste = (e: any) => {
      e.target.value = String(e.target.value).replaceAll(' ', '');
      if (!Number(String(e.target.value).replace(',', '.'))) {
        formData.amount = undefined;
      }
    };

    return {
      clearUnNumberValuePerPaste,
      amountKeydown,
      closeModal,
      buyContract,
      isErrorInput,
      dividingNumber,
      contractsVariables,
      modalName,
      periodsList,
      formData,
      isNodeAmountMoreInputAmount,
    };
  },
});
