

import tc from '@/hooks/tc';
import {
  defineComponent, PropType, computed,
} from 'vue';

export default defineComponent({
  name: 'CommonInput',
  components: {},

  emits: ['update:modelValue', 'keydown', 'innerRef'],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number] as PropType<string | number>,
      required: true,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    height: {
      type: [Number, String],
      required: false,
    },
    maxLength: {
      type: Number,
      required: false,
    },
    minValue: {
      type: Number,
      required: false,
      default: -Infinity,
    },
    maxValue: {
      type: Number,
      required: false,
      default: Infinity,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorLabel: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'xl'>,
      required: false,
      default: 'md',
    },
    paddingLeft: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
    },
    mask: {
      type: [String, Object],
      // default: () => ({ mask: 'Z*', tokens: { Z: { pattern: /[\s\S]/ } } }),
      default: () => (null),
    },
    fontWeight: {
      type: [Number, String],
    },
    autocomplete: String,
    inputPrefixClass: String,
    inputSuffixClass: String,
    label: String,
  },
  setup(props, { emit, slots }) {
    const t = tc();
    const inputStyle = computed(() => ({
      height: props.height || '',
      fontWeight: props.fontWeight || '',
    }));
    const inputClass = computed(() => ([
      'input',
      props.size,
      {
        hasError:
          props.hasError
          || !!props.error
          || (props.maxLength && props.maxLength < String(props.modelValue).length)
          || (props.minValue > Number(props.modelValue)
            || props.maxValue < Number(props.modelValue)),
        withPrefix: !!slots.prefix,
        withSuffix: !!slots.suffix,
      },
    ]));

    const autoError = computed(() => {
      if (props.minValue > Number(props.modelValue)) {
        return t('Минимальное значение {value}', { value: props.minValue });
      }
      if (props.maxValue < Number(props.modelValue)) {
        return t('Максимальное значение {value}', { value: props.maxValue });
      }
      return '';
    });

    return {
      autoError,
      updateInput(e: any) {
        emit('update:modelValue', e.target.value);
      },
      keydown(e: any) {
        emit('keydown', e);
      },
      inputClass,
      inputStyle,
    };
  },

});
