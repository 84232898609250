/* eslint-disable import/no-cycle */
import store from '@/store';
import routeHash from './routeHash';

export const closeModal = () => {
  routeHash.push(store.getters.routeStore.hash.tab === 'purchases' ? { tab: store.getters.routeStore.hash.tab } : {}, true);
};

export const openModal = (modalName: string, rest?: {[key: string]: any}) => routeHash.push(
  {
    modal: modalName,
    ...(rest || {}),
  },
  false,
);
