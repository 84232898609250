import {
  INIT, PENDING, FULFILLED, REJECTED,
} from '@/utils/statuses';
import codes from '@/utils/api/codes';
import { DefaultOfficeResponse } from '@/utils/api/apiTypes';
import { pathOr } from 'rambda';

export type State = typeof INIT | typeof PENDING | typeof FULFILLED | typeof REJECTED;

export interface FetchStateInterface<responseType> {
  fetchState: State;
  response: responseType | null;
}

export type FetchOfficeState<dataType> = FetchStateInterface<DefaultOfficeResponse<dataType>>;

export const getFetchState = <dataType>(): FetchStateInterface<dataType> => ({
  fetchState: INIT as State,
  response: null,
});

export const getFetchMutations = () => ({
  SET_STATE(state: any, { fetchState, data }: { fetchState: State; data?: any }) {
    state.fetchState = fetchState;

    if (data) {
      state.response = data;
    }
  },
  CLEAR_STORE(state: any) {
    state.fetchState = INIT;
    state.response = null;
  },
});

export interface FetchProps {
  method: any;
  props?: {
    url: string;

    [key: string]: any;
  };
  setState({ fetchState, data }: { fetchState: State; data?: any }): void;
  callback?(state: State, resp?: any): void;
}

const onError = (ctx: FetchProps, e: any, resp?: any) => {
  ctx.setState({ fetchState: REJECTED });
  console.error(new Error(e));

  if (typeof ctx.callback === 'function') {
    ctx.callback(REJECTED, resp);
  }
};

const fetch = async (ctx: FetchProps) => {
  if (typeof ctx.method !== 'function' || typeof ctx.setState !== 'function') {
    throw new Error(
      'Error in method storeUtils.fetch, ctx.method or ctx.setState is not a function',
    );
  }

  try {
    ctx.setState({ fetchState: PENDING });

    const response = await ctx.method(ctx.props);

    return response;
  } catch (e: any) {
    return onError(ctx, e);
  }
};

export const basicOfficeFetch = async (ctx: FetchProps) => {
  let response;

  try {
    response = await fetch(ctx);
  } catch (e: any) {
    console.error(e);
    return;
  }

  const code = pathOr(1, ['data', 'code'], response);
  const codeIsPassed = codes[0] === codes[code];

  if (codeIsPassed) {
    ctx.setState({ fetchState: FULFILLED, data: response });

    if (typeof ctx.callback === 'function') {
      ctx.callback(FULFILLED, response);
    }
  } else {
    onError(ctx, `codeError: ${code}`, response);
  }
};

/**
 * @deprecated
 */
export const basicFetch = basicOfficeFetch;

export const basicAdapterFetch = async (ctx: FetchProps) => {
  let response;

  try {
    response = await fetch(ctx);
  } catch (e: any) {
    console.error(e);
    return;
  }

  if (!response) {
    ctx.setState({ fetchState: REJECTED, data: response });
    return;
  }

  ctx.setState({ fetchState: FULFILLED, data: response });

  if (typeof ctx.callback === 'function') {
    ctx.callback(FULFILLED, response);
  }
};
