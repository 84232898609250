import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b17942e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "commonTabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tab.label,
        class: _normalizeClass(['commonTabsItem', { active: tab.value === _ctx.currentTab }]),
        onClick: ($event: any) => (_ctx.$emit('click', tab.value))
      }, _toDisplayString(_ctx.$t(tab.label)), 11, _hoisted_2))
    }), 128))
  ]))
}