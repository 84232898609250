/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { AxiosResponse } from 'axios';

import { pathOr, clone } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import { basicOfficeFetch, State } from '@/store/storeUtils';
import {
  INIT, PENDING, FULFILLED,
} from '@/utils/statuses';
import { ReferralTreeUser } from '@/interfaces/ReferralTreeTypes';

export type ReferralResponse = AxiosResponse<{ data: ReferralTreeUser }>;
export type ReferralDataType = {[key: number]: ReferralTreeUser[]};

export interface ReferralStateInterface {
  data: ReferralDataType;
  fetchState: State;
}

const initialState: ReferralStateInterface = {
  data: {},
  fetchState: INIT,
};

const referralTreeStore = defineModule({
  namespaced: true,
  state: clone(initialState),

  getters: {
    fetchState(...args): State {
      const { state } = getterCtx(args);

      if (Object.keys(state.data).length === 0) {
        return state.fetchState;
      }
      return FULFILLED;
    },
    users(...args): ReferralDataType {
      const { state } = getterCtx(args);

      return state.data;
    },
  },
  mutations: {
    CLEAR_STORE(state) {
      Object.assign(state, clone(initialState));
    },
    SET_STATE(state, { fetchState, data, userId }: { fetchState: State; data?: ReferralResponse, userId?: number }) {
      state.fetchState = fetchState;

      if (data && userId) {
        const list = pathOr([], 'data.data', data);

        state.data[userId] = list;
      }
    },
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {
    async fetch(ctx, props: { fromZero?: boolean, userId: number }) {
      const { commit, state } = actionCtx(ctx);

      if (state.data[props.userId]) {
        return;
      }

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/linear/data',
          data: {
            user_id: props.userId,
          },
        },
        setState: ({ fetchState }) => {
          if (fetchState !== FULFILLED) {
            commit.SET_STATE({ fetchState, userId: props.userId });
          }
        },
        callback: (fetchState, response) => {
          if (fetchState === FULFILLED) {
            commit.SET_STATE({ fetchState, data: response, userId: props.userId });
          }
        },
      });
    },
    async clear(ctx) {
      const { commit } = actionCtx(ctx);
      commit.CLEAR_STORE();
    },
  },
});

export default referralTreeStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, referralTreeStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, referralTreeStore);
