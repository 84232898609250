
import '@/assets/icons/mastercard-icon.svg?sprite';
import store from '@/store';
import { computed, defineComponent } from 'vue';
import {
  pipe,
  max,
  multiply,
} from 'rambda';
import curr from 'currency.js';
import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import changeCurr from '@/utils/changeCurr';
import { useI18n } from 'vue-i18n';
import payment from 'payment';
import CommonInput from './CommonInput.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonModal from './CommonModal.vue';
import CommonTabs from './CommonTabs.vue';

export const tabsList = [
  { value: 'card', label: 'Bank card' },
  // { value: 'bank', label: 'Bank account' },
  { value: 'adv', label: 'AdvCash' },
  { value: 'btc', label: 'Bitcoin' },
  { value: 'paystree', label: 'OTON Paystree' },
];

export default defineComponent({
  name: 'ModalWithdrawEuro',
  components: {
    CommonModal,
    CommonTabs,
    CommonInput,
    CommonButtonPrimary,
  },
  setup() {
    const { t } = useI18n();
    const modalName = modalsNames.modalWithdrawEuro;

    const hash = computed(() => store.getters.routeStore.hash);
    const data = computed(() => ({
      card: hash.value.card || '',
      cardname: hash.value.cardname || '',
      swift: hash.value.swift || '',
      iban: hash.value.iban || '',
      advcash: hash.value.advcash || '',
      btcaddress: hash.value.btcaddress || '',
      beneficiaryName: hash.value.beneficiaryName || '',

      sum: hash.value.sum ? String(hash.value.sum) : '',

      tab: (tabsList.some(({ value }) => value === hash.value.tab) ? hash.value.tab : 'card') as 'card' | 'adv' | 'btc',
    }));

    const commission = computed(() => ({
      card: {
        label: `4,5% + 5 $. ${t('But at least 10 €')}`,
        calc: pipe<any[], number, number>(
          (sum: number) => (sum * 0.045) + 5,
          max<number>(10),
        ),
      },
      adv: {
        label: '3%',
        calc: multiply(0.03),
      },
      btc: {
        label: `1,5%. ${t('Minimum withdrawal amount is {sum} {curr}', { sum: changeCurr('btc', 'usd', 0.01).format(), curr: '$' })}`,
        calc: multiply(0.015),
      },
      paystree: {
        label: t('No fee'),
        calc: multiply(0),
      },
    }));

    const forms = computed<{
      [key: string]: Array<{
        type: string;
        label: string;
        mask?: string;
        name: string;
        autocomplete?: string;
        addClass?: string;
        hasError: boolean;
      }>
    }>(() => {
      const {
        card, advcash, iban,
      } = data.value;

      return ({
        card: [
          {
            type: 'string',
            label: 'Bank card number (Mastercard)',
            mask: '#### #### #### ####',
            name: 'card',
            autocomplete: 'cc-number',
            get hasError() {
              if (!card) {
                return false;
              }

              return !payment.fns.validateCardNumber(String(card)) || payment.fns.cardType(String(card)) !== 'mastercard';
            },

          },
          {
            type: 'string',
            label: 'Имя как на карте',
            name: 'cardname',
            autocomplete: 'cc-name',
            get hasError() {
              return false;
            },

          },
        ],
        // bank: [
        //   {
        //     type: 'string',
        //     label: 'Bank SWIFT code',
        //     name: 'swift',
        //   },
        //   {
        //     type: 'string',
        //     label: 'IBAN',
        //     mask: 'XXXX XXXX XXXX XXXX',
        //     name: 'iban',
        //   },
        // ],
        adv: [
          {
            type: 'string',
            label: 'AdvCash account',
            mask: 'E #### #### ####',
            placeholder: 'E',
            name: 'advcash',
            get hasError() {
              if (!advcash) {
                return false;
              }

              return String(advcash).replace(/\s/g, '').length !== 13;
            },
          },
        ],
        btc: [
          {
            type: 'string',
            label: 'BTC address',
            name: 'btcaddress',
            addClass: 'fluid',
            get hasError() {
              return false;
            },
          },
        ],
        paystree: [
          {
            type: 'string',
            label: 'Beneficiary name',
            name: 'beneficiaryName',
            get hasError() {
              return false;
            },
          },
          {
            type: 'string',
            label: 'IBAN',
            name: 'iban',
            mask: 'GB ## XXXXXX XXXXX XXXXXXX',
            placeholder: 'GB',
            get hasError() {
              if (!iban) {
                return false;
              }

              return String(iban).replace(/\s/g, '').length !== 22;
            },
          },
        ],
      });
    });

    const formIsValid = computed(() => forms.value[data.value.tab]
      .every((form: any) => !form.hasError));

    const sumInBtc = computed(() => changeCurr('usd', 'btc', data.value.sum, { precision: 6 })
      .format()
      .replace(/(0)+$/, '')
      .replace(/\.$/, ''));
    const oneBtcInEur = computed(() => changeCurr('btc', 'usd', 1).format());

    const eurRateSettings = store.getters.exchangeRatesStore.ratesSettings.usd;
    const fee = computed(() => curr(
      commission.value[data.value.tab].calc((+data.value.sum || 0)) as any,
      eurRateSettings,
    ));

    const sumWithFee = computed(() => fee.value.add(data.value.sum));

    const isSumVaid = computed(() => {
      const sum = +data.value.sum || 0;
      let isValid = false;

      isValid = sum > fee.value.value;

      if (data.value.tab === 'btc') {
        isValid = isValid && sum >= changeCurr('btc', 'usd', 0.01).value;
      }

      return isValid;
    });

    const onChange = (name: string, value: string) => {
      routeHash.replace({ [name]: value });
    };
    const changeTab = (name: string) => {
      onChange('tab', name);
    };
    const onSubmit = () => modals.modalWithdrawEuroAccept.openModal({
      ...data.value,
      fee: fee.value.format(),
      sumWithFee: sumWithFee.value.value,
    });

    return {
      modalName,
      tabsList,
      forms,
      data,
      commission,
      fee,
      sumWithFee,
      onChange,
      onSubmit,
      changeTab,
      isSumVaid,
      formIsValid,
      changeCurr,
      sumInBtc,
      oneBtcInEur,
    };
  },
});
