import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonSpinner = _resolveComponent("CommonSpinner")!

  return (_ctx.state === _ctx.$status.PENDING || _ctx.state === _ctx.$status.INIT || _ctx.active)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['commonLoader', { placeholder: _ctx.isPlaceholder, top: _ctx.top }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['spinnerBox', { spinnerBox__small: _ctx.isSmallLoader }])
        }, [
          _createVNode(_component_CommonSpinner)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}