/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { pathOr, keys } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';
import curr from 'currency.js';

import {
  FetchOfficeState, getFetchState, getFetchMutations, basicFetch,
} from '@/store/storeUtils';
import { FULFILLED } from '@/utils/statuses';

export interface TransactionData {
  [key: string]: string | number;
}
export interface TransactionInfoDataItem {
  label: string;
  value: string | number;
}

export interface RatesResponseInterface {
  // отношение btc к euro
  btc: number;
  // отношение [currencyName] к битку
  btce: number;
  dash: number;
  date: number;
  eth: number;
  eur: number;
  hrk: number;
  id: number;
  oton: number;
  otonh: number;
  otonb: number;
  rub: number;
  try: number;
  uah: number;
  usd: number;
  usdt: number;
  busdt: number;
  ate: number;
  ates: number;
  ateb: number;
}

export type SupportedCurrencies = keyof RatesResponseInterface;

export type RatesType = {
  [K in SupportedCurrencies]: curr;
};

export interface ExchangeRatesStateInterface extends FetchOfficeState<RatesResponseInterface> {
  lastFetch: number;
  watchers: number[];
}

const exchangeRatesStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<RatesResponseInterface>(),
    lastFetch: 0,
    watchers: [],
  } as ExchangeRatesStateInterface,

  getters: {
    canFetch(...args): boolean {
      const { state } = getterCtx(args);
      const now = Date.now();
      const fiveMin = 5 * 60 * 1000;

      return now - state.lastFetch > fiveMin;
    },

    ratesSettings(): { [K in SupportedCurrencies]: curr.Options } {
      const settings: { [K in SupportedCurrencies]: curr.Options } = {
        btc: { precision: 8 },
        btce: { precision: 2 },
        dash: { precision: 8 },
        date: { precision: 8 },
        eth: { precision: 8 },
        eur: { precision: 4 },
        hrk: { precision: 8 },
        id: { precision: 8 },
        oton: { precision: 3 },
        otonh: { precision: 3 },
        otonb: { precision: 3 },
        rub: { precision: 2 },
        try: { precision: 8 },
        uah: { precision: 8 },
        usd: { precision: 2 },
        usdt: { precision: 5 },
        busdt: { precision: 4 },
        ate: { precision: 8 },
        ates: { precision: 8 },
        ateb: { precision: 8 },
      };

      keys(settings).forEach((key) => {
        settings[key] = {
          ...settings[key],
          separator: ' ',
          decimal: '.',
          symbol: '',
        };
      });

      return settings;
    },

    rates(...args): RatesType {
      const { state, getters } = getterCtx(args);

      const initialRates: RatesResponseInterface = {
        btc: 0,
        btce: 0,
        dash: 0,
        date: 0,
        eth: 0,
        eur: 0,
        hrk: 0,
        id: 0,
        oton: 0,
        otonh: 0,
        otonb: 0,
        rub: 0,
        try: 0,
        uah: 0,
        usd: 0,
        usdt: 0,
        busdt: 0,
        ate: 0,
        ates: 0,
        ateb: 0,
      };

      const rates = pathOr(initialRates, ['data', 'data'], state.response);

      const result = {} as RatesType;

      keys(rates).forEach((key) => {
        result[key] = curr(rates[key], getters.ratesSettings[key]);
      });

      // HACK ON
      result.btc = curr(1, getters.ratesSettings.btc);
      // HACH OFF

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    UPDATE_LAST_FETCH(state) {
      state.lastFetch = Date.now();
    },
    CLEAR_TIMEOUTS(state) {
      state.watchers.forEach((n) => {
        window.clearTimeout(n);
      });

      state.watchers = [];
    },
  },
  actions: {
    async fetch(ctx) {
      const { commit, dispatch, getters } = actionCtx(ctx);

      if (!getters.canFetch) {
        return;
      }

      await basicFetch({
        method: api.office.sendPost,
        props: {
          url: '/index/getRates',
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === FULFILLED) {
            commit.UPDATE_LAST_FETCH();
            commit.CLEAR_TIMEOUTS();

            window.setTimeout(
              dispatch.fetch,
              5 * 60 * 1000, // 5 min
            );
          }
        },
      });
    },
  },
});

export default exchangeRatesStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, exchangeRatesStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, exchangeRatesStore);
