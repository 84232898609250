
import { defineComponent, PropType } from 'vue';

export interface CommonTabItem {
  label: string;
  value: any;
}
export default defineComponent({
  name: 'CommonTabs',
  components: {},
  emits: ['click'],
  props: {
    currentTab: String,
    tabs: {
      type: Array as PropType<CommonTabItem[]>,
      required: true,
    },
  },
});
