/* eslint-disable import/no-cycle */
import isDev from '@/utils/isDev';
import { createDirectStore } from 'direct-vuex';
import { createLogger } from 'vuex';
import router from '@/router';
import routeStore, { RouteState } from './modules/routeStore';
import transactionInfoModalStore from './modules/transactionInfoModalStore';
import transferOtonFlowStore from './modules/transferOtonFlowStore';
import exchangeRatesStore from './modules/exchangeRatesStore';
import userStore from './modules/userStore';
import multitransactionsStore from './modules/multitransactionsStore';
import transactionsStore from './modules/transactionsStore';
import transactionsTypesStore from './modules/transactionsTypesStore';
import contactsUserStore from './modules/contactsUserStore';
import twoFaStore from './modules/twoFaStore';
import transferTokensStore from './modules/transferTokensStore';
import withdrawTokensStore from './modules/withdrawTokensStore';
import userEmailStore from './modules/userEmailStore';
import contractsStore from './modules/contractsStore';
import confirmModalStore from './modules/confirmModalStore';
import otonLiquidityStore from './modules/otonLiquidityStore';
import otonVolumeStore from './modules/otonVolumeStore';
import otonVolume24hStore from './modules/otonVolume24hStore';
import otonRateStore from './modules/otonRateStore';
import otonUsdtRateStore from './modules/otonUsdtRateStore';
import twoAuthCodeStore from './modules/twoAuthCodeStore';
import documentsAcceptionStore from './modules/documentsAcceptionStore';
import purchasesStore from './modules/purchasesStore';
import rankStore from './modules/rankStore';
import turnoverStore from './modules/turnoverStore';
import poolsInfoStore from './modules/poolsInfoStore';
import referralTreeStore from './modules/referralTreeStore';
import userDashboardStore from './modules/userDashboardStore';
import statsStore from './modules/statsStore';
import purchasesInfoModalStore from './modules/purchasesInfoModalStore';
import userBalance from './modules/userBalance';

const {
  store,
  rootActionContext: rootActionCtx,
  moduleActionContext: modActionCtx,
  rootGetterContext: rootGetterCtx,
  moduleGetterContext: modGetterCtx,
} = createDirectStore({
  modules: {
    routeStore,
    userBalance,
    twoFaStore,
    userStore,
    transactionInfoModalStore,
    transferOtonFlowStore,
    exchangeRatesStore,
    multitransactionsStore,
    transactionsStore,
    transactionsTypesStore,
    contactsUserStore,
    withdrawTokensStore,
    transferTokensStore,
    userEmailStore,
    contractsStore,
    confirmModalStore,
    otonLiquidityStore,
    otonVolumeStore,
    otonVolume24hStore,
    otonRateStore,
    otonUsdtRateStore,
    twoAuthCodeStore,
    documentsAcceptionStore,
    purchasesStore,
    rankStore,
    turnoverStore,
    poolsInfoStore,
    referralTreeStore,
    userDashboardStore,
    statsStore,
    purchasesInfoModalStore,
  },
  plugins: isDev ? [createLogger()] : [],
});

export const sync = () => {
  let isTimeTraveling = false;
  let currentPath: string;

  // sync router on store change
  const storeUnwatch = store.original.watch(
    (state) => state.routeStore.route,
    (route: RouteState) => {
      const { fullPath } = route;
      if (fullPath === currentPath) {
        return;
      }
      if (currentPath != null) {
        isTimeTraveling = true;
        router.push(route);
      }
      currentPath = fullPath;
    },
    { flush: 'sync' },
  );

  // sync store on router navigation
  const afterEachUnHook = router.afterEach((to, from) => {
    if (isTimeTraveling) {
      isTimeTraveling = false;
      return;
    }

    currentPath = to.fullPath;

    store.commit.routeStore.ROUTE_CHANGED({ to, from });
  });

  return function unsync() {
    // remove router hook
    afterEachUnHook();

    // remove store watch
    storeUnwatch();
  };
};

sync();

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionCtx, modActionCtx, rootGetterCtx, modGetterCtx,
};

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
  // eslint-disable-next-line
  interface Store<S> {
    direct: AppStore;
  }
}
