/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  basicOfficeFetch,
  getFetchState,
  FetchStateInterface,
  getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED, INIT,
} from '@/utils/statuses';

export interface RankBarResponseInterface {
  lastPayout: number | string;
  missedPayout: {
    amount: number | string;
    miss: string;
    shares: number;
  };
  next: string;
  pointsPeriod: object;
  qualify: boolean;
  rank: number | string;
  youWillGet: number | string;
}

export interface RankBarStoreInterface extends FetchOfficeState<RankBarResponseInterface> {
  data: RankBarResponseInterface;
}

const RankStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<RankBarResponseInterface>(),
  } as FetchStateInterface<RankBarResponseInterface>,

  getters: {
    data(...args): RankBarResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path(['data', 'data'], state.response) as RankBarResponseInterface;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/pool/user/pointsBar',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in twoAuthCodeStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default RankStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, RankStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, RankStore);
