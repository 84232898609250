/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import { keys } from 'rambda';
import { modGetterCtx } from '@/store/index';

import { modalsNames } from '@/utils/modals';

export interface TransactionData {
  [key: string]: string | number;
}
export interface TransactionInfoDataItem {
  label: string;
  value: string | number;
}

const transactionInfoModalStore = defineModule({
  namespaced: true,
  state: {},

  getters: {
    isOpen(...args): boolean {
      const { rootGetters } = getterCtx(args);

      return rootGetters.routeStore.currentModalOpen === modalsNames.trnInfo;
    },

    infoData(...args): TransactionInfoDataItem[] {
      const { rootGetters } = getterCtx(args);
      const currentData = rootGetters.routeStore.currentModalData as { [key: string]: any };

      if (currentData && !Array.isArray(currentData)) {
        const items: TransactionInfoDataItem[] = [];

        keys(currentData).forEach((label) => {
          const value = currentData[label];
          if (
            (typeof value === 'string' || typeof value === 'number')
            && typeof label === 'string'
          ) {
            items.push({ value, label });
          }
        });

        return items;
      }

      return [];
    },
  },
  mutations: {},
  actions: {},
});

export default transactionInfoModalStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, transactionInfoModalStore);
// const actionCtx = (ctx: any) => modActionCtx(ctx, transactionInfoModalStore);
