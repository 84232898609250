
import store from '@/store';
import {
  computed, defineComponent,
} from 'vue';
import '@/assets/icons/close-icon.svg?sprite';

import modals, { modalsNames } from '@/utils/modals';
import routeHash from '@/utils/routeHash';
import { FULFILLED, INIT } from '@/utils/statuses';
import { State } from '@/store/storeUtils';
import CommonModal from './CommonModal.vue';
import CommonButtonPrimary from './CommonButtonPrimary.vue';
import CommonButtonSecondary from './CommonButtonSecondary.vue';

import CommonLoader from './CommonLoader.vue';

export default defineComponent({
  name: 'ModalWithdrawEuroAccept',
  components: {
    CommonButtonSecondary, CommonButtonPrimary, CommonModal, CommonLoader,
  },
  setup() {
    const withdrawState = computed(() => {
      const { state } = store.state.withdrawTokensStore;
      if (state === INIT) {
        return FULFILLED;
      }
      return state;
    });
    const modalName = modalsNames.modalWithdrawEuroAccept;

    const hash = computed(() => store.getters.routeStore.hash);
    const hideContent = computed(() => !hash.value.modal);
    const isDone = computed(() => !!hash.value.isDone || !hash.value.modal);

    const data = computed(() => ({
      card: (hash.value.card || '') as string,
      cardname: (hash.value.cardname || '') as string,
      swift: (hash.value.swift || '') as string,
      iban: (hash.value.iban || '') as string,
      advcash: (hash.value.advcash || '') as string,
      btcaddress: (hash.value.btcaddress || '') as string,
      beneficiaryName: (hash.value.beneficiaryName || '') as string,

      sum: (hash.value.sum || '') as string,
      fee: (hash.value.fee || '') as string,
      sumWithFee: (hash.value.sumWithFee || '') as string,

      tab: (hash.value.tab || 'card') as 'card' | 'adv' | 'btc',
    }));

    const closeHandler = modals.modalWithdrawEuroAccept.closeModal;
    const onBack = () => modals.modalWithdrawEuro.openModal(data.value);
    const onSend = () => {
      const {
        tab, sumWithFee, card, advcash, btcaddress, beneficiaryName, iban, cardname,
      } = data.value;

      const destinations = {
        card: `${card}|--|${cardname}`,
        adv: advcash,
        btc: btcaddress,
        paystree: `paystree|--|${iban}|--|${beneficiaryName}`,
      };

      const destination = destinations[tab] || '';

      store.dispatch.withdrawTokensStore.fetch({
        callback(state: State) {
          if (state === FULFILLED) {
            routeHash.replace({ ...data.value, isDone: '1' });
          }
        },
        amount: sumWithFee,
        currency: 22,
        destination,
      });
    };

    return {
      withdrawState,
      forms: {
        card: [
          {
            label: 'Bank card number (Mastercard)',
            name: 'card',
          },
          {
            label: 'Имя как на карте',
            name: 'cardname',
          },
        ],
        bank: [
          {
            label: 'Bank SWIFT code',
            name: 'swift',
          },
          {
            label: 'IBAN',
            name: 'iban',
          },
        ],
        adv: [
          {
            label: 'AdvCash account',
            name: 'advcash',
          },
        ],
        btc: [
          {
            label: 'BTC address',
            name: 'btcaddress',
          },
        ],
        paystree: [
          {
            label: 'Beneficiary name',
            name: 'beneficiaryName',
          },
          {
            label: 'IBAN',
            name: 'iban',
          },
        ],
      },
      modalName,
      isDone,
      hideContent,
      data,
      closeHandler,
      onBack,
      onSend,
    };
  },
});
