
import { computed, defineComponent } from 'vue';
import { modalsNames } from '@/utils/modals';

import store from '@/store';
import { pathOr, path } from 'rambda';
import curr from 'currency.js';
import CommonModal from './CommonModal.vue';

export default defineComponent({
  name: 'ModalContractsInfo',
  components: {
    CommonModal,
  },
  setup() {
    const modalName = modalsNames.contracts;

    const userData = computed(() => store.getters.userStore.data);
    const userBalance = computed(() => path(['balance'], userData.value));
    const number = computed(() => {
      const balance = pathOr(0, ['sft', 'balance'], userBalance.value);

      const bananceNormalized = curr(balance).value;

      return bananceNormalized;
    });

    return {
      modalName,
      userBalance,
      number,
    };
  },
});
