<template>
  <button class="SecondaryButton" @click="click">
    <slot />
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CommonButtonSecondary',
  emits: ['click'],
  props: {

  },
  methods: {
    click(e) {
      this.$emit('click', e);
    },
  },
});
</script>

<style lang="stylus" scoped>
.SecondaryButton {
  padding: 11px 14px;
  border-radius: 4px;
  border: 2px solid $colorPrimary;
  color: $colorFontBase;
  background-color: $colorFiller;
  cursor: pointer;
  user-select: none;
  getFontButton();

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
