
import store from '@/store';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { defineComponent, computed } from 'vue';
import { modalsNames } from '@/utils/modals';
import CommonModal from './CommonModal.vue';
import WithLoader from './WithLoader.vue';

export default defineComponent({
  name: 'ModalPurchasesInfo',
  components: {
    CommonModal,
    WithLoader,
  },
  props: {
    purchaseId: [String, Number],
  },

  setup() {
    const { d } = useI18n();

    const state = computed(() => store.state.purchasesInfoModalStore.fetchState);

    const modalName = modalsNames.modalPurchasesInfo;

    const purchaseInfo = computed(() => store.getters.purchasesInfoModalStore.data);

    const productsInfo = computed(() => purchaseInfo.value?.items);
    const transactionsInfo = computed(() => purchaseInfo.value?.transactions?.records);

    const dateDay = (date: number) => d(String(dayjs.unix(date).format('YYYY-MM-DD')), 'loopShortSecond');
    const getTime = (date: number) => String(dayjs.unix(date).format('HH:mm'));

    const currencies = {
      1: 'BTC',
      17: '4ART',
      18: 'OTON',
      19: 'CONCERTVR',
      20: 'TRADE',
      21: 'EBP',
      22: 'EUR',
      23: 'USDT',
      32: 'BUSDT',
      41: 'ATE',
    };

    return {
      modalName,
      productsInfo,
      transactionsInfo,
      dateDay,
      getTime,
      currencies,
      state,
    };
  },
});
