
import { defineComponent, computed, ref } from 'vue';
import dayjs from 'dayjs';
import store from '@/store';
import { pathOr, path } from 'rambda';
import { modalsNames } from '@/utils/modals';
import { initialState } from '@/store/modules/transactionsStore';
import CommonModal from './CommonModal.vue';
import CommonButtonMore from './CommonButtonMore.vue';
import WithLoader from './WithLoader.vue';

export default defineComponent({
  name: 'ModalAllBranches',
  components: {
    CommonModal,
    CommonButtonMore,
    WithLoader,
  },
  setup() {
    const isOpenTransactions = ref(false);

    const modalName = modalsNames.modalAllBranches;

    const userId = computed(() => path('id', store.getters.userStore.data));
    const turnover = computed(() => store.getters.turnoverStore.data);
    const structureObject: any = computed(() => pathOr({}, ['value', 'structure'], turnover));
    const structureList = computed(() => Object.keys(structureObject.value).map((k) => structureObject.value[k]).sort((item: any, item2: any) => item2.turnover - item.turnover));

    const transactionsList = computed(() => store.state.transactionsStore.data);
    const fetchMoreState = computed(() => store.getters.transactionsStore.fetchMoreState);
    const canFetchMore = computed(() => store.state.transactionsStore.canFetchMore);
    const { fetchForUser } = store.dispatch.transactionsStore;

    const fetchMore = (user_Id: number) => fetchForUser({ userId: user_Id });

    const openTransactions = (user_Id: number) => {
      if (!transactionsList.value || (transactionsList.value && Number(transactionsList.value[0]?.ref_user_id) !== Number(user_Id))) {
        fetchForUser({ fromZero: true, userId: user_Id });
      } else {
        store.dispatch.transactionsStore.clear();
      }
    };

    const { fetch } = store.dispatch.transactionsStore;

    const closeModal = () => {
      store.commit.transactionsStore.SET_FILTERS({ filters: initialState.filters });
      fetch({ fromZero: true });
    };

    const dateDay = (date: number) => String(dayjs.unix(date).format('DD.MM.YYYY HH:mm'));

    return {
      openTransactions,
      turnover,
      userId,
      structureList,
      modalName,
      transactionsList,
      isOpenTransactions,
      dateDay,
      fetchMoreState,
      canFetchMore,
      fetchMore,
      closeModal,
    };
  },
});
