/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import { ContractsItemsInterface } from '@/interfaces/contractsTypes';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';
import curr from 'currency.js';

import {
  FetchOfficeState,
  getFetchState,
  getFetchMutations,
  basicOfficeFetch,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

type StateInterface = FetchOfficeState<ContractsItemsInterface>;

const contractsStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<StateInterface>(),
  } as StateInterface,

  getters: {
    data(...args): ContractsItemsInterface | undefined {
      const { state } = getterCtx(args);

      let result = path('data.data', state.response) as ContractsItemsInterface;

      if (Array.isArray(result)) {
        result = result.map((item) => ({
          ...item,
          formattedValue: curr(item.value, { precision: 0, separator: ' ', symbol: '' }).format(),
        }));
      }

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/token/oton/contracts',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in contractsStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default contractsStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, contractsStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, contractsStore);
