/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { docsInfo, docsKeys, keysOfDocs } from '@/utils/getDoc';
import { isEmpty } from 'rambda';
import { reLogin } from '../../utils/regExp/loginPage';

export interface DocumentsAcceptionStoreStateInterface {
  requiredDocs: keysOfDocs[];
}

const requiredDocs = docsKeys
  .filter((docName) => docsInfo[docName].needAccept);

const initialState: DocumentsAcceptionStoreStateInterface = {
  requiredDocs,
};

const documentsAcceptionStore = defineModule({
  namespaced: true,
  state: {
    ...initialState,
  } as DocumentsAcceptionStoreStateInterface,
  getters: {

    needAccept(...args): boolean {
      const { getters, rootState } = getterCtx(args);

      const { isAuth } = rootState.userStore;
      const isNotLoginPage = !reLogin.test(rootState.routeStore.route.path);

      return !isEmpty(getters.docsForAccept) && isAuth && isNotLoginPage;
    },

    docsForAccept(...args): keysOfDocs[] {
      const { rootGetters } = getterCtx(args);

      const userData = rootGetters.userStore.data;

      if (!userData) {
        return [];
      }

      return requiredDocs.filter((key) => {
        const { acceptKey } = docsInfo[key];

        if (!acceptKey) {
          return false;
        }

        return Number(userData[acceptKey]) !== 1;
      });
    },
  },

  mutations: {
  },
  actions: {
    confirmAll(ctx) {
      const { rootDispatch, getters } = actionCtx(ctx);

      const { docsForAccept } = getters;
      const data: {
        [key:string]: {
          value: string,
          group: string,
        }
      } = {};

      docsForAccept.forEach((key) => {
        const { acceptKey } = docsInfo[key];

        if (!acceptKey) {
          return;
        }

        data[acceptKey] = {
          value: '1',
          group: 'terms',
        };
      });
      rootDispatch.userStore.setProfile({ data });
    },
  },
});

export default documentsAcceptionStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, documentsAcceptionStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, documentsAcceptionStore);
