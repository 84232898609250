import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d9abe9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalItemLabel" }
const _hoisted_2 = { class: "modalItemValue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    name: _ctx.modalName,
    title: _ctx.$t('Transaction')
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "modalItem",
          key: item.label
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(item.label), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(item.value), 1)
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["name", "title"]))
}