import { SupportedCurrencies } from '@/store/modules/exchangeRatesStore';
import store from '@/store';
import curr from 'currency.js';
import { FULFILLED } from './statuses';

export const getCurr = (
  currName: SupportedCurrencies,
  value: curr.Any,
  formatOptions?: curr.Options,
) => {
  const { ratesSettings } = store.getters.exchangeRatesStore;
  const opt: curr.Options = {
    ...ratesSettings[currName],
    ...(formatOptions || {}),
  };

  const result = curr(value, opt);

  const formattedResult = result.format();
  result.format = () => formattedResult.replace(/(\.0+)$/, '').replace(/\.$/, '');

  return result;
};

export default (
  from: SupportedCurrencies,
  to: SupportedCurrencies,
  value: curr.Any,
  formatOptions?: curr.Options,
): curr => {
  const { rates } = store.getters.exchangeRatesStore;
  const currentCurr = getCurr(to, value, formatOptions);

  if (from === to || store.state.exchangeRatesStore.fetchState !== FULFILLED) {
    return currentCurr;
  }

  try {
    const result = currentCurr
      .multiply(100000000)
      .divide(rates[from])
      .multiply(rates[to])
      .divide(100000000);

    const formattedResult = result.format();
    result.format = () => formattedResult.replace(/(\.0+)$/, '').replace(/\.$/, '');

    return result;
  } catch (e: any) {
    console.error(new Error(e));
    return curr(0);
  }
};
