/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { path } from 'rambda';
import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  FetchOfficeState,
  basicOfficeFetch,
  getFetchState,
  FetchStateInterface,
  getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED, INIT,
} from '@/utils/statuses';

export interface PoolsInfoResponseInterface {
  id: string | number;
  payout: string | number;
  percent: string | number;
  balance: string | number;
  shares: any;
  title: string | number;
}

export interface PoolsInfoStoreInterface extends FetchOfficeState<PoolsInfoResponseInterface> {
  data: PoolsInfoResponseInterface;
}

const poolsInfoStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<PoolsInfoResponseInterface>(),
  } as FetchStateInterface<PoolsInfoResponseInterface>,

  getters: {
    data(...args): PoolsInfoResponseInterface | undefined {
      const { state } = getterCtx(args);

      const result = path(['data', 'data', '1'], state.response) as PoolsInfoResponseInterface;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
    SET_INIT(state) {
      state.fetchState = INIT;
    },
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/pool/info',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in twoAuthCodeStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default poolsInfoStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, poolsInfoStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, poolsInfoStore);
